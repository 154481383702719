import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import establishSmartTipDetailsThunk, {
    updateSmartTipDetailsThunk,
    sendSmartTipFeedbackThunk,
} from 'redux/actions/thunks/smartTipDetails';
import establishOrganizationSmartTipDetailsThunk from 'redux/actions/thunks/organizationSmartTipDetails';
import {
    SmartTipDetailsView,
    OrganizationSmartTipDetailsView,
} from 'views/SmartTipDetailsView';
import useAdmin from 'hooks/useAdmin';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

const SmartTipDetails = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const { smartTipId } = useParams();
    const dispatch = useDispatch();
    const smartTipDetails = useSelector((state) => state.smartTipDetails);
    const organizationSmartTipDetails = useSelector(
        (state) => state.organizationSmartTipDetails
    );
    const [isVideoError, setIsVideoError] = useState(false);
    const isAdmin = useAdmin();

    const load = () => {
        if (isAdmin) {
            dispatch(
                establishOrganizationSmartTipDetailsThunk(
                    getAccessTokenSilently,
                    logout,
                    smartTipId
                )
            );
        } else {
            dispatch(
                establishSmartTipDetailsThunk(
                    getAccessTokenSilently,
                    logout,
                    smartTipId
                )
            );
        }
    };

    useEffect(() => {
        load();
    }, [smartTipId, isAdmin]);

    const updateSmartTip = (values) => {
        dispatch(
            updateSmartTipDetailsThunk(
                getAccessTokenSilently,
                logout,
                smartTipId,
                values
            )
        );
    };

    const sendFeedback = (values) => {
        dispatch(
            sendSmartTipFeedbackThunk(
                getAccessTokenSilently,
                logout,
                smartTipId,
                values
            )
        );
    };

    const handleVideoError = () => {
        setIsVideoError(true);
    };

    if (isAdmin) {
        return (
          <OrganizationSmartTipDetailsView
                isFetching={
                    organizationSmartTipDetails.areOrganizationSmartTipDetailsFetching
                }
                isAssignedToAll={
                    organizationSmartTipDetails.details.assigned_to_all
                }
                smartTipId={smartTipId}
                name={organizationSmartTipDetails.details.name}
                status={organizationSmartTipDetails.details.status}
                description={organizationSmartTipDetails.details.description}
                score={organizationSmartTipDetails.details.score}
                evaluationsCount={
                    organizationSmartTipDetails.details.evaluations_count
                }
                commentsCount={
                    organizationSmartTipDetails.details.comments_count
                }
                serveysEnabled={
                    organizationSmartTipDetails.details.surveys_enabled
                }
                source={{
                    id: organizationSmartTipDetails.details.source_id,
                    type: organizationSmartTipDetails.details.source_type,
                }}
                handleVideoError={handleVideoError}
                reload={load}
                isVideoError={isVideoError}
            />
        );
    }

    return (
      <SmartTipDetailsView
            onUpdate={updateSmartTip}
            onSubmitFeedback={sendFeedback}
            isFetching={smartTipDetails.areSmartTipDetailsFetching}
            name={smartTipDetails.details.name}
            status={smartTipDetails.details.status}
            description={smartTipDetails.details.description}
            source={{
                id: smartTipDetails.details.source_id,
                type: smartTipDetails.details.source_type,
            }}
            handleVideoError={handleVideoError}
            isVideoError={isVideoError}
            evaluation={smartTipDetails.details.evaluation}
        />
    );
};

export default SmartTipDetails;
