import styled from 'styled-components/macro';

export const ModalBody = styled.div`
    width: 830px;
    padding: 16px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        max-width: 830px;
        width: calc(100vw - 36px);
    }
`;

export const FilterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: ${({ theme }) => theme.color.bgSearch};
    border-radius: ${({ theme }) => theme.border.radiusBase};
`;

export const Filter = styled.div`
    width: 50%;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        width: 100%;
    }
`;

export const Label = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    margin-bottom: 4px;
`;

export const InputWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 16px;
`;

export const TableWrapper = styled.div`
    width: 100%;
    margin: 19px 0;
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    overflow: hidden;
    max-height: 380px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        max-height: 316px;
    }
`;

export const ListWrapper = styled.div`
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
`;

export const ListItem = styled.div`
    border-bottom: ${({ theme }) => theme.border.base};
    padding: 14px 24px;
`;

export const AmountOfResults = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 35px 32px;
    color: ${({ theme }) => theme.color.textBase};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        padding: 16px 32px;
    }
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-gap: 16px;
    padding: 24px 32px;
    border-top: ${({ theme }) => theme.border.base};
    border-bottom: ${({ theme }) => theme.border.base};
    background-color: ${({ theme }) => theme.color.bgPrimarySuperLight};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        padding: 12px 32px;
    }
`;

export const HeaderItem = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${({ theme }) => theme.color.textLight};
    display: flex;
    align-items: center;
`;

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 8px;

    & .tooltipText {
        padding: 10px;
        visibility: hidden;
        width: max-content;
        background-color: ${({ theme }) => theme.color.textBase};
        color: ${({ theme }) => theme.color.white};
        text-align: center;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        bottom: 40px;
        left: -50%;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        max-width: 180px;
    }

    & .tooltipText::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 17px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${({ theme }) => theme.color.textBase} transparent
            transparent transparent;
    }

    &:hover .tooltipText {
        visibility: visible;
    }

    svg {
        margin-bottom: -3px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        display: none;
    }
`;
