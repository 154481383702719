import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MetricModal from 'components/MetricModal';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import useDate from 'hooks/useDate';
import apiRoutes from 'config/apiRoutes';
import useFetch from 'hooks/useFetch';
import useAdmin from 'hooks/useAdmin';
import SectionHeader from 'components/SectionHeader';
import MetricDetailsModal from 'containers/MetricDetailsModal';
import UniversalEmptyState from 'components/EmptyState/UniversalEmptyState';
import { ROUTES } from 'routing/constants';
import { useAuth0 } from '@auth0/auth0-react';
import {
    accordionCustomStyles,
    MetricAccordionBody,
    LastUpdate,
} from './PerformanceMetrics.style';

const metricsReducer = (state, payload) => ({ ...state, ...payload });

const PerformanceMetrics = ({ handleMetricLoaded, userId }) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const isAdmin = useAdmin();
    const [disabledSources, setDisabledSources] = useState([]);
    const [metricSources, setMetricSources] = useState([]);
    const [metricsBySourceId, setMetricsBySourceId] = useReducer(
        metricsReducer,
        {}
    );

    useEffect(() => {
        let reqUrl;

        if (userId && userId.length > 0) {
            reqUrl = apiRoutes.get.organizationMetricSourcesForSingleUser;
        } else {
            reqUrl = isAdmin
                ? apiRoutes.get.organizationMetricSources
                : apiRoutes.get.providerMetricSources;
        }

        request(
            reqUrl,
            'GET',
            (res) => {
                setMetricSources(res.metric_sources);
            },
            (err) => err,
            {},
            { 'Content-Type': 'application/json' }
        );
    }, []);

    const fetchMetricsBySourceId = (sourceId) => {
        let reqUrl;

        if (userId && userId.length > 0) {
            reqUrl = apiRoutes.get.organizationMetricsBySourceIdForSingleUser(
                userId,
                sourceId
            );
        } else {
            reqUrl = isAdmin
                ? apiRoutes.get.organizationMetricsBySourceId(sourceId)
                : apiRoutes.get.metricsBySourceId(sourceId);
        }

        request(
            reqUrl,
            'GET',
            (res) => {
                setMetricsBySourceId({
                    [sourceId]: [...res.metrics],
                });
            },
            (err) => err,
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    const GetDate = (date) => useDate(date);

    const getScoring = (metric) => {
        if (isAdmin) {
            return userId && userId.length > 0
                ? metric.result
                : metric.overall_score;
        }
        return metric.result;
    };

    useEffect(() => {
        // Populate the metrics state so accordions can be opened on page render
        metricSources.forEach((ms) => {
            fetchMetricsBySourceId(ms.id);
            if (!ms.enabled) {
                setDisabledSources([...disabledSources, ms.id]);
            }
        });
    }, [metricSources]);

    useEffect(() => {
        handleMetricLoaded();
    }, [metricsBySourceId, metricSources]);

    const renderMetricResult = (metric) =>
        metric.smart_tip_id ? (
            <Link
                to={`${ROUTES.smartTips}/${metric.smart_tip_id}`}
                key={metric.id}
                style={{ maxWidth: 'calc(100vw - 48px)' }}
            >
                <MetricModal
                    title={metric.name}
                    subtitle={metric.description || 'No description'}
                    scoring={getScoring(metric)}
                    goal={metric.goal}
                    customCta={() => (
                        <Button primary fullWidth>
                            Go to Smart Tip
                        </Button>
                    )}
                />
            </Link>
        ) : (
            <MetricModal
                key={metric.id}
                title={metric.name}
                subtitle={metric.description || 'No description'}
                scoring={metric.result}
                goal={metric.goal}
            />
        );

    if ((!isAdmin && !metricSources) || metricSources.length === 0) {
        return <></>;
    }

    return (
        <>
            <SectionHeader title="Performance Metrics" />
            {isAdmin && (!metricSources || metricSources.length === 0) && (
                <UniversalEmptyState description="There are no Metrics or Data Sources available to your organization at the moment." />
            )}
            {metricSources.map((metricSource) => (
                <Accordion
                    key={metricSource.id}
                    label={metricSource.name}
                    customStyles={accordionCustomStyles}
                    customOnClick={() =>
                        fetchMetricsBySourceId(metricSource.id)
                    }
                    rightSideCta={() => (
                        <>
                            {metricSource.last_results_imported_at && (
                                <LastUpdate>
                                    {`Last update: ${GetDate(
                                        new Date(
                                            metricSource.last_results_imported_at
                                        )
                                    )}`}
                                </LastUpdate>
                            )}
                        </>
                    )}
                    disabledSource={disabledSources.includes(metricSource.id)}
                    initiallyOpen
                >
                    <MetricAccordionBody>
                        <MetricDetailsModal
                            sourceId={metricSource.id}
                            userId={userId}
                            isUserView={userId && userId.length > 0}
                        />
                        {metricsBySourceId[metricSource.id] &&
                            metricsBySourceId[metricSource.id].map(
                                (singleMetric) =>
                                    renderMetricResult(singleMetric)
                            )}
                    </MetricAccordionBody>
                </Accordion>
            ))}
        </>
    );
};

PerformanceMetrics.propTypes = {
    userId: PropTypes.oneOfType([PropTypes.string, undefined]),
    handleMetricLoaded: PropTypes.func.isRequired,
};

PerformanceMetrics.defaultProps = {
    userId: undefined,
};

export default PerformanceMetrics;
