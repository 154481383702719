import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import {
    Dropdown,
    DropdownOption,
    DropdownOptions,
} from '../charts/RelativePerformance/RelativePerformance.style';
import { ReactComponent as ArrowUp } from '../../assets/svg/dropdown-up.svg';
import theme from '../../assets/theme/mainTheme';
import { ReactComponent as ArrowDown } from '../../assets/svg/dropdown-down.svg';
import apiRoutes from '../../config/apiRoutes';
import { dispatchErrorAlertThunk } from '../../redux/actions/thunks/systemAlerts';
import useFetch from '../../hooks/useFetch';
import { NestTagListContainer, WrapperTags, SeparatorTags } from './styled';
import { ClassificationTagChip } from './ClassificationTagChip';

export const ClassificationTagDropdown = ({
    currentlySavedTags = {
        role_job: [],
        department: [],
    },
    setClassificationTags,
    localMode = false,
    updateUrl,
    layout = 'vertical',
    teleportChipsLocRef = null,
    disabled,
    setClassificationTagIds,
}) => {
    const layoutStylesContainer = {
        vertical: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            marginBottom: 10,
        },
        horizontal: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
            marginBottom: 10,
        },
    };

    const layoutStylesFirstDropdown = {
        vertical: {},
        horizontal: { marginRight: 16 },
    };

    const dispatch = useDispatch();
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);
    const [tags, setTags] = useState([]);

    const roleJobTags = currentlySavedTags.role_job
        ? currentlySavedTags.role_job.map((t) => ({
              ...t,
              classification: 'role_job',
          }))
        : [];

    const departmentTags = currentlySavedTags.department
        ? currentlySavedTags.department.map((t) => ({
              ...t,
              classification: 'department',
          }))
        : [];

    const allTags = [...departmentTags, ...roleJobTags];

    const assignTags =
        (newTag, classification, remove = false) =>
        () => {
            let classificationTagIds;
            if (remove) {
                classificationTagIds = allTags
                    .map((t) => t.id)
                    .filter((t) => t !== newTag.id);
            } else {
                classificationTagIds = [...allTags.map((t) => t.id), newTag.id];
            }

            if (localMode) {
                const newState = {
                    department: [...currentlySavedTags.department],
                    role_job: [...currentlySavedTags.role_job],
                };

                if (classification === 'department' && !remove) {
                    newState.department.push(newTag);
                }

                if (classification === 'department' && remove) {
                    newState.department = newState.department.filter(
                        (t) => t.id !== newTag.id
                    );
                }

                if (classification === 'role_job' && remove) {
                    newState.role_job = newState.role_job.filter(
                        (t) => t.id !== newTag.id
                    );
                }

                if (classification === 'role_job' && !remove) {
                    newState.role_job.push(newTag);
                }
                if (setClassificationTagIds)
                    setClassificationTagIds(classificationTagIds);
                setClassificationTags(newState);
                return;
            }
            request(
                // TO JEST TO
                updateUrl,
                'POST',
                (res) => {
                    setClassificationTags(res.classification_tags);
                },
                (err) => {
                    dispatch(
                        dispatchErrorAlertThunk(
                            'saveClassificationTreeError',
                            err.error
                        )
                    );
                },
                {
                    body: JSON.stringify({
                        classification_tag_ids: classificationTagIds,
                    }),
                },
                { 'Content-Type': 'application/json' }
            );
        };

    const fetchTags = () => {
        request(
            apiRoutes.get.classificationTags,
            'GET',
            (res) => {
                setTags(res);
            },
            (err) => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'saveClassificationTreeError',
                        err.error
                    )
                );
            },
            {},
            { 'Content-Type': 'application/json' }
        );
    };

    useEffect(fetchTags, []);

    const [isRolesDropdownVisible, setIsRolesDropdownVisible] = useState(false);
    const [isLocationsDropdownVisible, setIsLocationsDropdownVisible] =
        useState(false);

    const toggleLocationsDropdownVisible = () => {
        setIsLocationsDropdownVisible(!isLocationsDropdownVisible);
    };

    const toggleRolesDropdownVisible = () => {
        setIsRolesDropdownVisible(!isRolesDropdownVisible);
    };

    const rolesDropdownRef = useRef(null);
    const locationsDropdownRef = useRef(null);

    const handleClickOutside = (e) => {
        if (
            rolesDropdownRef.current &&
            rolesDropdownRef.current.contains(e.target)
        ) {
            return;
        }

        if (
            locationsDropdownRef.current &&
            locationsDropdownRef.current.contains(e.target)
        ) {
            return;
        }
        setIsRolesDropdownVisible(false);
        setIsLocationsDropdownVisible(false);
    };

    useEffect(() => {
        if (isRolesDropdownVisible || isLocationsDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isRolesDropdownVisible, isLocationsDropdownVisible]);

    const renderOpts = (classification) =>
        function (tagsLocal, root = true) {
            if (!tagsLocal) return null;

            return (
                <div style={{ marginLeft: root ? 0 : 30 }}>
                    {tagsLocal.map((t) => (
                        <div key={t.id}>
                            <DropdownOption
                                onClick={assignTags(t, classification)}
                            >
                                {t.name}
                            </DropdownOption>
                            {renderOpts(classification)(
                                t.subclassifications,
                                false
                            )}
                        </div>
                    ))}
                </div>
            );
        };

    const classificationTags = (type) =>
        allTags
            .filter((tag) => tag.classification === type)
            .map((t) => (
                <ClassificationTagChip
                    key={t.id}
                    tag={t}
                    onClick={assignTags(t, t.classification, true)}
                />
            ));

    const chips = disabled ? null : (
        <WrapperTags>
            {classificationTags('department')}
            {classificationTags('department').length > 0 &&
                classificationTags('role_job').length > 0 && <SeparatorTags />}
            {classificationTags('role_job')}
        </WrapperTags>
    );

    return (
        <>
            <div style={layoutStylesContainer[layout]}>
                <Dropdown
                    disabled={disabled}
                    onClick={
                        disabled ? () => {} : toggleLocationsDropdownVisible
                    }
                    ref={locationsDropdownRef}
                    style={{
                        marginBottom: 16,
                        background: 'white',
                        ...layoutStylesFirstDropdown[layout],
                    }}
                >
                    Department
                    {isLocationsDropdownVisible ? (
                        <ArrowUp fill={theme.color.textBase} />
                    ) : (
                        <ArrowDown fill={theme.color.textBase} />
                    )}
                    {isLocationsDropdownVisible && (
                        <DropdownOptions>
                            <NestTagListContainer>
                                {renderOpts('department')(tags.department)}
                            </NestTagListContainer>
                        </DropdownOptions>
                    )}
                </Dropdown>

                <Dropdown
                    onClick={disabled ? () => {} : toggleRolesDropdownVisible}
                    ref={rolesDropdownRef}
                    style={{ marginBottom: 16, background: 'white' }}
                    disabled={disabled}
                >
                    Role/Job
                    {isRolesDropdownVisible ? (
                        <ArrowUp fill={theme.color.textBase} />
                    ) : (
                        <ArrowDown fill={theme.color.textBase} />
                    )}
                    {isRolesDropdownVisible && (
                        <DropdownOptions>
                            <NestTagListContainer>
                                {renderOpts('role_job')(tags.role_job)}
                            </NestTagListContainer>
                        </DropdownOptions>
                    )}
                </Dropdown>
            </div>
            {teleportChipsLocRef && teleportChipsLocRef.current
                ? createPortal(chips, teleportChipsLocRef.current)
                : chips}
        </>
    );
};

ClassificationTagDropdown.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    currentlySavedTags: PropTypes.object.isRequired,
    setClassificationTags: PropTypes.func.isRequired,
    localMode: PropTypes.bool,
    updateUrl: PropTypes.string,
    layout: PropTypes.string,
    teleportChipsLocRef: PropTypes.node,
    disabled: PropTypes.bool,
    setClassificationTagIds: PropTypes.func,
};

ClassificationTagDropdown.defaultProps = {
    localMode: false,
    updateUrl: '',
    layout: 'vertical',
    teleportChipsLocRef: null,
    disabled: false,
    setClassificationTagIds: undefined,
};
