import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FeedbackIcon1 } from 'assets/svg/survey/smiley-1.svg';
import { ReactComponent as FeedbackIcon2 } from 'assets/svg/survey/smiley-2.svg';
import { ReactComponent as FeedbackIcon3 } from 'assets/svg/survey/smiley-3.svg';
import { ReactComponent as FeedbackIcon4 } from 'assets/svg/survey/smiley-4.svg';
import { ReactComponent as FeedbackIcon5 } from 'assets/svg/survey/smiley-5.svg';
import theme from 'assets/theme/mainTheme';

import ReviewModal from 'components/ReviewModal';
import StyledFeedbackWrapper, {
    StyledFeedbackSelection,
    StyledFeedbackGroup,
    StyledFeedbackButton,
} from './SmartTipFeedback.style';

const SmartTipFeedback = ({ onSubmit, evaluation }) => {
    const [reviewInput, setReviewInput] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(0); // numeric value corresponding to button numbers

    const [isModalVisible, setIsVisibleModal] = useState(false);
    const [feedbackExplainerText, setFeedbackExplainerText] = useState('');
    const [buttonNumber, setButtonNumber] = useState(0);

    useEffect(() => {
        if (buttonNumber) {
            switch (buttonNumber) {
                case 1:
                    setFeedbackExplainerText('Very Unsatisfied');
                    break;

                case 2:
                    setFeedbackExplainerText('Unsatisfied');
                    break;

                case 3:
                    setFeedbackExplainerText('Neutral');
                    break;

                case 4:
                    setFeedbackExplainerText('Satisfied');
                    break;

                case 5:
                    setFeedbackExplainerText('Very Satisfied');
                    break;

                default:
                    break;
            }
        } else if (!buttonNumber) {
            setFeedbackExplainerText('');
        } else {
            setFeedbackExplainerText('');
        }
    }, [buttonNumber]);

    const onSubmitFeedback = () =>
        onSubmit({ value: feedbackSubmitted, comment: reviewInput });

    const handleSelection = () => {
        if (buttonNumber) {
            setFeedbackSubmitted(buttonNumber);

            if (buttonNumber > 0 && buttonNumber < 4) {
                setIsVisibleModal(true);
            } else {
                onSubmit({ value: buttonNumber, comment: '' });
            }
        }
    };

    const isSelected = (number) => evaluation === number && buttonNumber === 0;

    const onMouseEnter = (number) => {
        setButtonNumber(number);
    };

    return (
      <StyledFeedbackWrapper>
        Let us know what you think about this Smart Tip!
        {isModalVisible && (
        <ReviewModal
                    closeModal={() => setIsVisibleModal(false)}
                    cancelFeedback={() => {
                        setFeedbackSubmitted(0);
                    }}
                    onSubmit={onSubmitFeedback}
                    review={{
                        value: reviewInput,
                        setFunc: setReviewInput,
                    }}
                    noOverflow
                />
            )}
        <StyledFeedbackSelection buttonNumber={buttonNumber} isCompleted>
          <strong>{feedbackExplainerText}</strong>
          <StyledFeedbackGroup>
            <StyledFeedbackButton
                        isCompleted
                        onMouseEnter={() => onMouseEnter(1)}
                        onMouseLeave={() => setButtonNumber(0)}
                        onClick={() => handleSelection(1)}
                        isSelected={isSelected(1)}
                        bgColor={theme.color.quaternary}
                    >
              <FeedbackIcon1 />
            </StyledFeedbackButton>

            <StyledFeedbackButton
                        isCompleted
                        onMouseEnter={() => onMouseEnter(2)}
                        onMouseLeave={() => setButtonNumber(0)}
                        onClick={() => handleSelection(2)}
                        isSelected={isSelected(2)}
                        bgColor={theme.color.quaternaryAlt}
                    >
              <FeedbackIcon2 />
            </StyledFeedbackButton>

            <StyledFeedbackButton
                        isCompleted
                        onMouseEnter={() => onMouseEnter(3)}
                        onMouseLeave={() => setButtonNumber(0)}
                        onClick={() => handleSelection(3)}
                        isSelected={isSelected(3)}
                        bgColor={theme.color.tertiaryAlt}
                    >
              <FeedbackIcon3 />
            </StyledFeedbackButton>

            <StyledFeedbackButton
                        isCompleted
                        onMouseEnter={() => onMouseEnter(4)}
                        onMouseLeave={() => setButtonNumber(0)}
                        onClick={() => handleSelection(4)}
                        isSelected={isSelected(4)}
                        bgColor={theme.color.paleGreen}
                    >
              <FeedbackIcon4 />
            </StyledFeedbackButton>

            <StyledFeedbackButton
                        isCompleted
                        onMouseEnter={() => onMouseEnter(5)}
                        onMouseLeave={() => setButtonNumber(0)}
                        onClick={() => handleSelection(5)}
                        isSelected={isSelected(5)}
                        bgColor={theme.color.primary}
                    >
              <FeedbackIcon5 />
            </StyledFeedbackButton>
          </StyledFeedbackGroup>
        </StyledFeedbackSelection>
      </StyledFeedbackWrapper>
    );
};

SmartTipFeedback.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    evaluation: PropTypes.number,
};

SmartTipFeedback.defaultProps = {
    evaluation: 0,
};

export default SmartTipFeedback;
