// eslint-disable react/no-array-index-key
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { FieldArray, Formik, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Input from '../components/Input';
import { ReactComponent as BinIcon } from '../assets/svg/bin.svg';
import Button from '../components/Button';
import { AccordionContent } from '../components/Accordion/Accordion.style';
import { ReactComponent as VectorDownIcon } from '../assets/svg/vectorDown.svg';
import './UsersSatisfaction/styles.css';
import useFetch from '../hooks/useFetch';
import apiRoutes from '../config/apiRoutes';
import SectionHeader from '../components/SectionHeader';
import ConfirmActionModal from '../components/ConfirmActionModal';
import {
    dispatchErrorAlertThunk,
    dispatchSuccessAlertThunk,
} from '../redux/actions/thunks/systemAlerts';
import BackToTopButton from '../components/BackToTopButton';

const TABS = {
    ROLE_JOB: 'ROLE_JOB',
    DEPARTMENT: 'DEPARTMENT',
};

const TAB_LEVEL_TO_LABELS = {
    [TABS.ROLE_JOB]: {
        1: 'Role/Job',
        2: 'Subcategory',
        3: 'Sub-Subcategory',
    },
    [TABS.DEPARTMENT]: {
        1: 'Department',
        2: 'Subcategory',
        3: 'Sub-Subcategory',
    },
};

const BIN_ICON_SIZE = 18;

const commonBinIconProps = {
    className: 'bin-icon',
    height: BIN_ICON_SIZE,
    width: BIN_ICON_SIZE,
};

const VALIDATION_SCHEMA = Yup.object().shape({
    levelOne: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Field cannot be left empty'),
            levelTwo: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required('Field cannot be left empty'),
                    levelThree: Yup.array().of(
                        Yup.object().shape({
                            name: Yup.string().required(
                                'Field cannot be left empty'
                            ),
                        })
                    ),
                })
            ),
        })
    ),
});

const InputRow = ({ children }) => (
    <div className="classification-input-row">{children}</div>
);

export const BoxContainer = styled.div`
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.border.base};
    border-radius: ${({ theme }) => theme.border.radiusBase};
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 16px 32px;
`;

InputRow.propTypes = {
    children: PropTypes.node.isRequired,
};

const treeNodeKey = (node) => node.id || node.feId;

const notMarkedToDestroy = (item) => !item._destroy;

export const UserClassifications = () => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const request = useFetch(getAccessTokenSilently, logout);

    const [formChanged, setFormChanged] = useState(false);
    const [namesData, setNamesData] = useState([]);
    const [newTags, setNewTags] = useState([]);
    const [uniqError, setUniqError] = useState([]);
    const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
    const [currentTab, setCurrentTab] = useState(TABS.DEPARTMENT);
    const [errorFromFormik, setErrorFromFormik] = useState(false);
    const [
        initialRoleJobClassificationTags,
        setInitialRoleJobClassificationTags,
    ] = useState([]);
    const [
        initialDepartmentClassificationTags,
        setInitialDepartmentClassificationTags,
    ] = useState([]);

    const history = useHistory();

    const getNestedFormikError = (errors, touched, name) => {
        const touch = getIn(touched, name);
        const error = getIn(errors, name);

        if (touch && error) {
            setErrorFromFormik(true);
        } else {
            setErrorFromFormik(false);
        }

        return touch && error ? error : undefined;
    };

    const fetchTags = () => {
        request(
            apiRoutes.get.classificationTags,
            'GET',
            (res) => {
                setInitialRoleJobClassificationTags(res.role_job);
                setInitialDepartmentClassificationTags(res.department);
            },
            (err) => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'saveClassificationTreeError',
                        err.error
                    )
                );
            },
            {},
            { 'Content-Type': 'application/json' }
        );
    };
    useEffect(fetchTags, []);
    const dispatch = useDispatch();

    const updateRequest = (formParams) =>
        request(
            apiRoutes.put.updateClassificationTags,
            'POST',
            () => {
                fetchTags();
                dispatch(
                    dispatchSuccessAlertThunk(
                        'saveClassificationTreeSuccess',
                        'Classification tree has been successfully saved'
                    )
                );
                setFormChanged(false);
            },
            (err) => {
                dispatch(
                    dispatchErrorAlertThunk(
                        'saveClassificationTreeError',
                        err.error
                    )
                );
            },
            { body: JSON.stringify(formParams) },
            { 'Content-Type': 'application/json' },
            true
        );

    const [hiddenItemsMap, setHiddenItemsMap] = useState({});

    const toggleExpandedItem = (id) => {
        setHiddenItemsMap({ ...hiddenItemsMap, [id]: !hiddenItemsMap[id] });
    };

    const rotationStyle = (item) => ({
        margin: 15,
        transform: !hiddenItemsMap[item.id || item.feId]
            ? 'rotate(0deg)'
            : 'rotate(180deg)',
    });

    const tabToPersistedValues = {
        [TABS.ROLE_JOB]: initialRoleJobClassificationTags,
        [TABS.DEPARTMENT]: initialDepartmentClassificationTags,
    };

    const tabToClassification = {
        [TABS.ROLE_JOB]: 'role_job',
        [TABS.DEPARTMENT]: 'department',
    };

    const saveContRef = useRef(null);

    const formInitValues = {
        levelOne: tabToPersistedValues[currentTab].map((l) => ({
            name: l.name,
            id: l.id,
            levelTwo: l.subclassifications.map((ll) => ({
                name: ll.name,
                id: ll.id,
                levelThree: ll.subclassifications.map((lll) => ({
                    name: lll.name,
                    id: lll.id,
                })),
            })),
        })),
    };

    const onSubmit = (values) => {
        const fParams = {
            trees: values.levelOne.map((dep) => ({
                name: dep.name.trim(),
                classification: tabToClassification[currentTab],
                id: dep.id,
                _destroy: dep._destroy,
                children: dep.levelTwo.map((s) => ({
                    name: s.name.trim(),
                    classification: tabToClassification[currentTab],
                    id: s.id,
                    _destroy: s._destroy,
                    children: s.levelThree.map((ss) => ({
                        name: ss.name.trim(),
                        classification: tabToClassification[currentTab],
                        id: ss.id,
                        _destroy: ss._destroy,
                    })),
                })),
            })),
        };
        return updateRequest(fParams);
    };

    const prepareAllNameOfData = () => {
        const tags = [
            ...initialRoleJobClassificationTags,
            ...initialDepartmentClassificationTags,
        ];

        let spreadTags = [];

        tags.forEach((l) => {
            spreadTags = [...spreadTags, { name: l.name, id: l.id }];
            if (l.subclassifications) {
                l.subclassifications.forEach((ll) => {
                    spreadTags = [...spreadTags, { name: ll.name, id: ll.id }];
                    if (ll.subclassifications) {
                        ll.subclassifications.forEach((lll) => {
                            spreadTags = [
                                ...spreadTags,
                                { name: lll.name, id: lll.id },
                            ];
                        });
                    }
                });
            }
        });

        setNamesData(spreadTags);
    };

    const generateNewTagsList = (newTag) => {
        const isExists = newTags.find((t) => t.name === newTag.name);
        const foundExistingIndex = newTags.findIndex(
            (t) => t.name === newTag.name
        );

        if (!isExists) {
            setNewTags([...newTags, newTag]);
        } else {
            const copyArr = [...newTags];
            copyArr.splice(foundExistingIndex, 1);
            copyArr.splice(foundExistingIndex, 1, newTag);
            setNewTags(copyArr);
        }
    };

    const generateTagsFromDatabase = (newTag) => {
        const foundValue = newTags.find((t) => t.value === newTag.value);
        const foundInitialValue = namesData.find(
            (d) => d.name === newTag.value && d.id !== newTag.id
        );

        const foundInitialIndex = namesData.findIndex(
            (d) => d.id === newTag.id
        );
        const copyNamesData = [...namesData];
        if (foundInitialIndex >= 0) copyNamesData.splice(foundInitialIndex, 1);

        setNamesData(copyNamesData);

        if (foundInitialValue || foundValue)
            setUniqError([...uniqError, newTag]);
    };

    const foundErrorsElement = (prevState, newTag) => {
        if (prevState.length) {
            const state = [...prevState];
            const foundIndexElement = state.findIndex(
                (item) => item.id === newTag.id
            );
            if (foundIndexElement >= 0) state.splice(foundIndexElement, 1);
            return state;
        }
        return [];
    };

    const onChangeNewFormValue = (value, name, id) => {
        const newTag = { value, name, id };
        setUniqError((prevState) => foundErrorsElement(prevState, newTag));
        generateNewTagsList(newTag);
        generateTagsFromDatabase(newTag);
    };

    const renderUniqErrorMessage = (name) => {
        const isError = uniqError.some((item) => item.name === name);
        return isError ? 'Name has already been taken' : false;
    };

    useEffect(() => {
        prepareAllNameOfData();
        setUniqError([]);
    }, [
        initialRoleJobClassificationTags,
        initialDepartmentClassificationTags,
        currentTab,
    ]);

    const handleOnChange = (event) => {
        event.preventDefault();
        if (event) {
            setFormChanged(true);
        } else {
            setFormChanged(false);
        }
    };

    const closeActionModal = () => {
        setVisibleConfirmModal((prevState) => !prevState);
    };

    const acceptConfirmModal = () => {
        setVisibleConfirmModal(true);
        setFormChanged(false);
        // eslint-disable-next-line consistent-return
        setCurrentTab(() => {
            if (currentTab === TABS.ROLE_JOB) return TABS.DEPARTMENT;
            if (currentTab === TABS.DEPARTMENT) return TABS.ROLE_JOB;
        });
    };

    const buildChangeTabHandler = (tab) => {
        if (formChanged) {
            setVisibleConfirmModal(true);
        } else {
            setCurrentTab(tab);
        }
    };

    useEffect(() => {
        const unblock = history.block(
            // eslint-disable-next-line consistent-return
            () => {
                if (formChanged) {
                    // eslint-disable-next-line no-alert
                    return window.confirm(
                        'Are you sure you want to leave without save?'
                    );
                }
            },
            []
        );

        return () => {
            unblock();
        };
    }, [formChanged]);

    return (
        <div>
            <SectionHeader
                title="Tags"
                cta={() => (
                    <div
                        ref={(el) => {
                            saveContRef.current = el;
                        }}
                    />
                )}
            />
            <nav style={{ margin: '20px 0px' }}>
                <Button
                    onClick={() => buildChangeTabHandler(TABS.DEPARTMENT)}
                    primary={currentTab === TABS.DEPARTMENT}
                    textLight={currentTab !== TABS.DEPARTMENT}
                >
                    Department
                </Button>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => buildChangeTabHandler(TABS.ROLE_JOB)}
                    primary={currentTab === TABS.ROLE_JOB}
                    textLight={currentTab !== TABS.ROLE_JOB}
                >
                    Role/Job
                </Button>
            </nav>

            <form
                onSubmit={(e) => e.preventDefault()}
                onChange={handleOnChange}
            >
                <Formik
                    initialValues={formInitValues}
                    validationSchema={VALIDATION_SCHEMA}
                    onSubmit={onSubmit}
                    validateOnChange
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        submitForm,
                    }) => (
                        <>
                            <FieldArray
                                name="levelOne"
                                render={(arrayHelpersDeps) => (
                                    <>
                                        {values.levelOne.map((d, depIndex) => (
                                            <BoxContainer
                                                key={treeNodeKey(d)}
                                                style={
                                                    !notMarkedToDestroy(d)
                                                        ? { display: 'none' }
                                                        : {}
                                                }
                                            >
                                                <InputRow>
                                                    <VectorDownIcon
                                                        style={rotationStyle(d)}
                                                        onClick={() =>
                                                            toggleExpandedItem(
                                                                treeNodeKey(d)
                                                            )
                                                        }
                                                    />
                                                    <Input
                                                        name={`levelOne[${depIndex}].name`}
                                                        error={
                                                            renderUniqErrorMessage(
                                                                `levelOne[${depIndex}].name`
                                                            ) ||
                                                            getNestedFormikError(
                                                                errors,
                                                                touched,
                                                                `levelOne[${depIndex}].name`
                                                            )
                                                        }
                                                        placeholder={
                                                            TAB_LEVEL_TO_LABELS[
                                                                currentTab
                                                            ]['1']
                                                        }
                                                        variant="base"
                                                        value={d.name}
                                                        onChange={({
                                                            target: { value },
                                                        }) => {
                                                            onChangeNewFormValue(
                                                                value,
                                                                `levelOne[${depIndex}].name`,
                                                                d.id
                                                            );
                                                            setFieldTouched(
                                                                `levelOne[${depIndex}].name`,
                                                                true
                                                            );
                                                            setFieldValue(
                                                                `levelOne[${depIndex}].name`,
                                                                value
                                                            );
                                                        }}
                                                    />

                                                    <BinIcon
                                                        {...commonBinIconProps}
                                                        onClick={() => {
                                                            if (d.id) {
                                                                setFieldValue(
                                                                    `levelOne[${depIndex}]._destroy`,
                                                                    1
                                                                );
                                                            } else {
                                                                arrayHelpersDeps.remove(
                                                                    depIndex
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </InputRow>
                                                <AccordionContent
                                                    className="accordionContent"
                                                    style={{
                                                        overflowY: 'auto',
                                                    }}
                                                    expanded={
                                                        !hiddenItemsMap[
                                                            d.id || d.feId
                                                        ]
                                                    }
                                                >
                                                    <FieldArray
                                                        name={`levelOne[${depIndex}].levelTwo`}
                                                        render={(
                                                            arrayHelpersSpecialities
                                                        ) => (
                                                            <div
                                                                style={{
                                                                    marginLeft: 40,
                                                                }}
                                                            >
                                                                {d.levelTwo.map(
                                                                    (
                                                                        s,
                                                                        specIndex
                                                                    ) => (
                                                                        <div
                                                                            className="accordionContent__item"
                                                                            key={treeNodeKey(
                                                                                s
                                                                            )}
                                                                            style={
                                                                                !notMarkedToDestroy(
                                                                                    s
                                                                                )
                                                                                    ? {
                                                                                          display:
                                                                                              'none',
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            <InputRow>
                                                                                <VectorDownIcon
                                                                                    onClick={() =>
                                                                                        toggleExpandedItem(
                                                                                            s.id ||
                                                                                                s.feId
                                                                                        )
                                                                                    }
                                                                                    style={rotationStyle(
                                                                                        s
                                                                                    )}
                                                                                />
                                                                                <Input
                                                                                    name={`levelOne[${depIndex}].levelTwo[${specIndex}].name`}
                                                                                    error={
                                                                                        renderUniqErrorMessage(
                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].name`
                                                                                        ) ||
                                                                                        getNestedFormikError(
                                                                                            errors,
                                                                                            touched,
                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].name`
                                                                                        )
                                                                                    }
                                                                                    placeholder={
                                                                                        TAB_LEVEL_TO_LABELS[
                                                                                            currentTab
                                                                                        ][
                                                                                            '2'
                                                                                        ]
                                                                                    }
                                                                                    variant="base"
                                                                                    value={
                                                                                        s.name
                                                                                    }
                                                                                    onChange={({
                                                                                        target: {
                                                                                            value,
                                                                                        },
                                                                                    }) => {
                                                                                        onChangeNewFormValue(
                                                                                            value,
                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].name`,
                                                                                            s.id
                                                                                        );
                                                                                        setFieldTouched(
                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].name`,
                                                                                            true
                                                                                        );
                                                                                        setFieldValue(
                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].name`,
                                                                                            value
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <BinIcon
                                                                                    {...commonBinIconProps}
                                                                                    onClick={() => {
                                                                                        // debugger; // eslint-disable-line no-debugger
                                                                                        if (
                                                                                            s.id
                                                                                        ) {
                                                                                            setFieldValue(
                                                                                                `levelOne[${depIndex}].levelTwo[${specIndex}]._destroy`,
                                                                                                1
                                                                                            );
                                                                                        } else {
                                                                                            arrayHelpersSpecialities.remove(
                                                                                                specIndex
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </InputRow>

                                                                            <AccordionContent
                                                                                style={{
                                                                                    overflowY:
                                                                                        'auto',
                                                                                }}
                                                                                expanded={
                                                                                    !hiddenItemsMap[
                                                                                        s.id ||
                                                                                            s.feId
                                                                                    ]
                                                                                }
                                                                            >
                                                                                <FieldArray
                                                                                    name={`levelOne[${depIndex}].levelTwo[${specIndex}].levelThree`}
                                                                                    render={(
                                                                                        arrayHelpersSubSpecialities
                                                                                    ) => (
                                                                                        <div
                                                                                            style={{
                                                                                                marginLeft: 80,
                                                                                            }}
                                                                                        >
                                                                                            {s.levelThree.map(
                                                                                                (
                                                                                                    ss,
                                                                                                    subSpecIndex
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={treeNodeKey(
                                                                                                            ss
                                                                                                        )}
                                                                                                        style={
                                                                                                            !notMarkedToDestroy(
                                                                                                                ss
                                                                                                            )
                                                                                                                ? {
                                                                                                                      display:
                                                                                                                          'none',
                                                                                                                  }
                                                                                                                : {}
                                                                                                        }
                                                                                                    >
                                                                                                        <InputRow>
                                                                                                            <Input
                                                                                                                name={`levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`}
                                                                                                                error={
                                                                                                                    renderUniqErrorMessage(
                                                                                                                        `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`
                                                                                                                    ) ||
                                                                                                                    getNestedFormikError(
                                                                                                                        errors,
                                                                                                                        touched,
                                                                                                                        `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`
                                                                                                                    )
                                                                                                                }
                                                                                                                placeholder={
                                                                                                                    TAB_LEVEL_TO_LABELS[
                                                                                                                        currentTab
                                                                                                                    ][
                                                                                                                        '3'
                                                                                                                    ]
                                                                                                                }
                                                                                                                variant="base"
                                                                                                                value={
                                                                                                                    ss.name
                                                                                                                }
                                                                                                                onChange={({
                                                                                                                    target: {
                                                                                                                        value,
                                                                                                                    },
                                                                                                                }) => {
                                                                                                                    onChangeNewFormValue(
                                                                                                                        value,
                                                                                                                        `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`,
                                                                                                                        ss.id ||
                                                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`
                                                                                                                    );
                                                                                                                    setFieldTouched(
                                                                                                                        `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`,
                                                                                                                        true
                                                                                                                    );
                                                                                                                    setFieldValue(
                                                                                                                        `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}].name`,
                                                                                                                        value
                                                                                                                    );
                                                                                                                }}
                                                                                                            />

                                                                                                            <BinIcon
                                                                                                                {...commonBinIconProps}
                                                                                                                onClick={() => {
                                                                                                                    debugger; // eslint-disable-line no-debugger
                                                                                                                    if (
                                                                                                                        ss.id
                                                                                                                    ) {
                                                                                                                        setFieldValue(
                                                                                                                            `levelOne[${depIndex}].levelTwo[${specIndex}].levelThree[${subSpecIndex}]._destroy`,
                                                                                                                            1
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        arrayHelpersSubSpecialities.remove(
                                                                                                                            subSpecIndex
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </InputRow>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                            <Button
                                                                                                primary
                                                                                                white
                                                                                                onClick={() => {
                                                                                                    arrayHelpersSubSpecialities.push(
                                                                                                        {
                                                                                                            name: '',
                                                                                                            feId: uuidv4(),
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {`Add ${TAB_LEVEL_TO_LABELS[currentTab]['3']}`}
                                                                                            </Button>
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </AccordionContent>
                                                                        </div>
                                                                    )
                                                                )}
                                                                <Button
                                                                    primary
                                                                    onClick={() => {
                                                                        arrayHelpersSpecialities.push(
                                                                            {
                                                                                name: '',
                                                                                levelThree:
                                                                                    [],
                                                                                feId: uuidv4(),
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    {`Add ${TAB_LEVEL_TO_LABELS[currentTab]['2']}`}
                                                                </Button>
                                                            </div>
                                                        )}
                                                    />
                                                </AccordionContent>
                                            </BoxContainer>
                                        ))}

                                        <BoxContainer>
                                            <Button
                                                primary
                                                fullWidth
                                                onClick={() => {
                                                    arrayHelpersDeps.push({
                                                        name: '',
                                                        levelTwo: [],
                                                        feId: uuidv4(),
                                                    });
                                                }}
                                            >
                                                {`Add ${TAB_LEVEL_TO_LABELS[currentTab]['1']}`}
                                            </Button>
                                        </BoxContainer>
                                    </>
                                )}
                            />
                            {saveContRef &&
                                saveContRef.current &&
                                createPortal(
                                    <Button
                                        primaryDark
                                        onClick={submitForm}
                                        disabled={
                                            errorFromFormik || uniqError.name
                                        }
                                    >
                                        Save
                                    </Button>,
                                    saveContRef.current
                                )}
                        </>
                    )}
                </Formik>
            </form>

            <ConfirmActionModal
                isVisible={visibleConfirmModal}
                onToggle={closeActionModal}
                onConfirm={acceptConfirmModal}
                confirmLabel="Leave"
                description="Are you sure you want to leave without save?"
            />
            <BackToTopButton />
        </div>
    );
};
/* eslint-enable */
